import React, { useContext } from "react";
import { isLogged } from "../../utils/inStore";


import "../pages.css";
import Login from "../login/Login";
import Main from "../main/Main";

export default function Home() {
  const isLogIn = useContext(isLogged).logged;
  if (isLogIn) {
    return <Main />;
  } else {
    return <Login />;
  }
}
