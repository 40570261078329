import { Route, Routes } from "react-router";
import "./App.css";
import "./utils/all.min.css";
import Error from "./components/Error/Error";

import Home from "./pages/home/Home";

import LayoutMain from "./components/Layout/LayoutMain";
import Requests from "./pages/requests/Requests";
import Request from "./pages/requests/Request";
import Notifications from "./pages/Notifications/Notifications";
import Dep from "./pages/main/Dep";
import AddDepartment from "./pages/main/AddDepartment";
import Coupons from "./pages/coupons/Coupons";
import Coupon from "./pages/coupons/Coupon";
import AddCoupon from "./pages/coupons/AddCoupon";
import Settings from "./pages/settings/Settings";
import Support from "./pages/support/Support";
import MessageShow from "./pages/support/Message";
import Reply from "./pages/support/Reply";
import Customers from "./pages/custopmers/Customers";
import Customershow from "./pages/custopmers/Customer";
import AddCustomer from "./pages/custopmers/AddCustomer";
import Stores from "./pages/Stores/Stores";
import StroeShow from "./pages/Stores/Stroe";
import AddStore from "./pages/Stores/AddStore";
import Ads from "./pages/Ads/Ads";
import Ad from "./pages/Ads/Ad";
import AddAd from "./pages/Ads/AddAd";
import { useContext } from "react";
import { load } from "./utils/isLoadingStore";
import Loader from "./components/Loader/Loader";
import Products from "./pages/Products/Products";
import ProductPage from "./pages/Products/Product";
import AddProduct from "./pages/Products/AddProduct";

function App() {
  const isLoading = useContext(load).isLoading;
  return (
    <div className="almarai-regular">
      {isLoading && <Loader />}
      <LayoutMain>
        <Routes>
          <Route path="*" element={<Error />}></Route>
          <Route path="/" element={<Home />}></Route>
          <Route path="/requests" element={<Requests />}></Route>
          <Route path="/request" element={<Request />}></Route>
          <Route path="/notificatios" element={<Notifications />}></Route>

          <Route path="/dep" element={<Dep />}></Route>
          <Route path="/add-department" element={<AddDepartment />}></Route>

          <Route path="/coupons" element={<Coupons />}></Route>
          <Route path="/coupon" element={<Coupon />}></Route>
          <Route path="/add-coupon" element={<AddCoupon />}></Route>

          <Route path="/Settings" element={<Settings />}></Route>
          <Route path="/support" element={<Support />}></Route>
          <Route path="/message" element={<MessageShow />}></Route>
          <Route path="/reply" element={<Reply />}></Route>

          <Route path="/custopmers" element={<Customers />}></Route>
          <Route path="/customer" element={<Customershow />}></Route>
          <Route path="/add-customer" element={<AddCustomer />}></Route>

          <Route path="/shops" element={<Stores />}></Route>
          <Route path="/store" element={<StroeShow />}></Route>
          <Route path="/add-store" element={<AddStore />}></Route>

          <Route path="/products" element={<Products />}></Route>
          <Route path="/product" element={<ProductPage />}></Route>
          <Route path="/add-product" element={<AddProduct />}></Route>

          <Route path="/ads" element={<Ads />}></Route>
          <Route path="/ad" element={<Ad />}></Route>
          <Route path="/add-ads" element={<AddAd />}></Route>
        </Routes>
      </LayoutMain>
    </div>
  );
}

export default App;
