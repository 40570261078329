import React from "react";
import SideBarLink from "./SideBarLink";

export default function SideBarTop({ open, toggel }) {
  let links = [
    { name: "الاقسام", link: "", icon: "fa-solid fa-grip" },
    { name: "الكوبونات", link: "coupons", icon: "fa-solid fa-ticket" },
    { name: "المتاجر", link: "shops", icon: "fa-solid fa-shop" },
    { name: "المنتجات", link: "products", icon: "fa-brands fa-product-hunt" },
    { name: "العملاء", link: "custopmers", icon: "fa-solid fa-user" },
    { name: "الطلبات", link: "requests", icon: "fa-solid fa-file" },
    { name: "الاعلانات", link: "ads", icon: "fa-solid fa-flag" },
    { name: "مركز الدعم", link: "support", icon: "fa-solid fa-comment-dots" },
    { name: "ارسال الاشعارات", link: "notificatios", icon: "fa-solid fa-bell" },
    { name: "الاعدادات", link: "settings", icon: "fa-solid fa-gear" },
  ];
  return (
    <div
      style={{ height: `${open ? `${links.length * 3.7}rem` : "0px"}` }}
      className={`w-full px-4 mb-3 mt-[-.75rem] text-white overflow-hidden transition-all ease-in-out duration-500 ${
        !open ? "p-0" : "py-4  "
      }  bg-[#0D8F75] `}
    >
      {links.map((link, inx) => (
        <button
          key={link.name}
          className="w-full text-start pl-20"
          onClick={toggel}
        >
          <SideBarLink
            key={inx}
            li={link.link}
            text={link.name}
            icon={link.icon}
          />
        </button>
      ))}
    </div>
  );
}
