import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import useFetch from "../../utils/useGet";
import usePost from "../../utils/usePost";
import { useNavigate } from "react-router";

export default function AddStore() {
  const [info, setInfo] = useState({
    name: "",
    depart: "",
    coupons: [],
    file: null,
    daitails: "",
  });

  const { name, depart, file, coupons, daitails } = info;
  const handleInfoChange = (e) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };
  const getSelectedUserNames = (selectedIds) => {
    return allStores
      .filter((user) => selectedIds.includes(user.id))
      .map((user) => user.name)
      .join(", ");
  };

  const [searchQuery, setSearchQuery] = useState("");

  const allStores = [
    { name: "111", id: 11 },
    { name: "112", id: 12 },
    { name: "113", id: 13 },
    { name: "114", id: 14 },
    { name: "115", id: 15 },
  ];

  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filtered users based on search query
  const filteredUsers = allStores.filter((user) =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const deps = useFetch("admin/sections/all").data;

  const { postData } = usePost();
  const navigate = useNavigate();
  const addHandeller = async () => {
    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("description", daitails);
    formdata.append("section_id", depart);
    formdata.append("image", file);
    console.log(file);

    const result = await postData(`admin/stores/create`, formdata);
    result && navigate("/shops");
  };
  return (
    <div>
      <Layout title={"اضافة متجر"}>
        <div>
          <section //personal INFO
            className=" px-4 md:px-24 lg:px-48 my-8 md:flex flex-wrap items-center justify-center md:justify-between"
          >
            <div //name
              className=" my-2"
            >
              <h4 className=" inputTitle">اسم المتجر </h4>
              <input
                name="name"
                value={name}
                onChange={handleInfoChange}
                className=" input"
                type="text"
                placeholder=" اسم المتجر"
              />
            </div>
            <div //department
              className=" my-2"
            >
              <h4 className=" inputTitle"> قسم المتجر </h4>
              <select
                name="depart"
                value={depart}
                onChange={handleInfoChange}
                className=" input"
                type="text"
                placeholder=" اسم المتجر"
              >
                <option hidden selected>
                  قسم المتجر
                </option>
                {deps &&
                  deps.map((e) => (
                    <option key={e.id} value={e.id}>
                      {e.name}
                    </option>
                  ))}
              </select>
            </div>
            {/* <div //coupons
              className=" my-2"
            >
              <h4 className=" inputTitle">الكوبونات التابعة للمتجر</h4>
              <FormControl sx={{ width: "100%" }}>
              
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={coupons}
                  className=" input"
                  name="coupons"
                  onChange={handleInfoChange}
                  // input={<OutlinedInput label="المستخدمين" />}
                  renderValue={(selected) => getSelectedUserNames(selected)}
                  // MenuProps={MenuProps}
                >
                  <Box sx={{ padding: 1 }}>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </Box>
                  {filteredUsers.map((name) => (
                    <MenuItem key={name} value={name.id}>
                      <Checkbox checked={coupons.indexOf(name.id) > -1} />
                      <ListItemText primary={name.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div> */}
          </section>
          <div className="px-4 md:px-24 lg:px-48">
            <div //image
              className=" my-2  w-full"
            >
              <h4 className=" inputTitle">رفع صورة للمتجر</h4>
              {file ? (
                // if file or else
                <div className="relative">
                  {/* Display Image */}
                  <img
                    alt="cover"
                    className="max-w-full max-h-48 border rounded-md shadow-md mx-auto"
                    src={URL.createObjectURL(file)}
                  />
                </div>
              ) : (
                <div className="input2 h-[150px] flex items-center justify-center">
                  {/* Upload Image */}
                  <section>
                    <label
                      className="po cursor-pointer h-full w-full"
                      htmlFor="file"
                    >
                      <span className="w-full flex justify-center">
                        <i className="fa-solid fa-arrow-up-from-bracket w-4 text-[#6D4E1F] text-[18px] text-center"></i>
                      </span>
                      <h4 className="text-[#A3AED0] text-[12px] my-4">
                        قم برفع ملفات PNG , JPG
                      </h4>
                    </label>
                    <input
                      required
                      type="file"
                      id="file"
                      onChange={(e) => {
                        setInfo({ ...info, file: e.target.files[0] });
                      }}
                      style={{ display: "none" }}
                    />
                  </section>
                </div>
              )}
            </div>
            <div //daitails
              className=" my-2  w-full"
            >
              <h4 className=" inputTitle"> وصف المتجر </h4>
              <textarea
                name="daitails"
                value={daitails}
                onChange={handleInfoChange}
                className="  input2"
                type="text"
                rows={5}
                placeholder=" وصف المتجر"
              />
            </div>
            <section className=" my-4 w-full text-center">
              <button onClick={addHandeller} className="mainBtn">
                اضافة متجر
              </button>
            </section>
          </div>
        </div>
      </Layout>
    </div>
  );
}
