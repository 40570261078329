import React from "react";
import SideBarLink from "./SideBarLink";

export default function SideBar() {
  let links = [
    { name: "الاقسام", link: "", icon: "fa-solid fa-grip" },
    { name: "الكوبونات", link: "coupons", icon: "fa-solid fa-ticket" },
    { name: "المتاجر", link: "shops", icon: "fa-solid fa-shop" },
    { name: "المنتجات", link: "products", icon: "fa-brands fa-product-hunt" },
    { name: "العملاء", link: "custopmers", icon: "fa-solid fa-user" },
    { name: "الطلبات", link: "requests", icon: "fa-solid fa-file" },
    { name: "الاعلانات", link: "ads", icon: "fa-solid fa-flag" },
    { name: "مركز الدعم", link: "support", icon: "fa-solid fa-comment-dots" },
    { name: "ارسال الاشعارات", link: "notificatios", icon: "fa-solid fa-bell" },
    { name: "الاعدادات", link: "settings", icon: "fa-solid fa-gear" },
  ];
  return (
    <div className=" bg-[#0D8F75] overflow-y-auto h-screen">
      {/* <section className=" flex items-center justify-center py-2 bg-[#0D8F75] rounded-b">
         <p className="  h-[140px]" src="./imgs/logo.png" alt="Logo">
          LOGO
        </p> 
         <img className=" h-[140px]" src="./imgs/logo.png" alt="Logo" /> 
      </section> */}
      <section className=" px-4  text-white  pt-10 pb-4">
        {links.map((link, inx) => (
          <SideBarLink
            key={inx}
            li={link.link}
            text={link.name}
            icon={link.icon}
          />
        ))}
      </section>
    </div>
  );
}
