import Layout from "../../components/Layout/Layout";
import Status from "../../components/pages/Status";
import { Link } from "react-router-dom";
import useFetch from "../../utils/useGet";
import DataTable from "./Table";

export default function Products() {
  const { data } = useFetch("admin/products/all");

  return (
    <div>
      <Layout title="المنتجات">
        <section>
          <Status
            text={"جميع المنتجات"}
            num={data ? data.length : 0}
            colour={"fffff"}
          >
            <div
              className={`mx-2 rounded-2xl flex items-center justify-center h-[45px] w-[45px] bg-[#f8810a]/20`}
            >
              <i
                className={`fa-brands fa-product-hunt text-[20px] text-[#f8810a]`}
              ></i>
            </div>
          </Status>
          <div className=" flex justify-end">
            <Link to="/add-product">
              <button className="mainBtn">اضافة منتج جديد</button>
            </Link>
          </div>
        </section>
        {data && <DataTable allData={data} />}
      </Layout>
    </div>
  );
}
