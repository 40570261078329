import { useState, useEffect, useContext } from "react";
import { load } from "./isLoadingStore";
import Cookies from "js-cookie";

const useFetch = (endPoint, reload) => {
  const [data, setData] = useState(null);
  const setLoading = useContext(load).setIsLoading;
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!endPoint) return;

    const fetchData = async () => {
      setLoading(true);
      setError(null);
      let token = "10|b8MBVdKyoB8weJ23JtErm5YaeTKs4knPc6zlsfjV34f3a085";
      // let token = Cookies.get("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/api/${endPoint}`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const result = await response.json();
        setData(result);
        // console.log(result);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [endPoint, reload]);

  return { data };
};

export default useFetch;
