import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { useLocation, useNavigate } from "react-router";
import useFetch from "../../utils/useGet";
import useDelete from "../../utils/useDelete";

export default function Ad() {
  const searchPaeam = useLocation().search;
  const id = new URLSearchParams(searchPaeam).get("ad");
  const [edit, setEdit] = useState(false);
  const [img, setImg] = useState(
    "https://th.bing.com/th/id/OIP.9hetfdrodOfI9KzE_g_dDAAAAA?rs=1&pid=ImgDetMain"
  );
  const { data } = useFetch(`admin/advertisements/show/${id}`);
  useEffect(() => {
    if (data) {
      setImg(`${process.env.REACT_APP_URL}/${data.image_path}`);
      // setData(data.stores);
      // setForSearch(data.stores);
    }
  }, [data]);

  const { deleteData } = useDelete();

  const nvigate = useNavigate();

  const deleteStoreHandeller = async () => {
    let res = await deleteData(`admin/advertisements/delete/${id}`);

    if (res) {
      nvigate("/ads");
    }
  };
  return (
    <div>
      <Layout title={"عرض الاعلان"}>
        <div className=" md:flex">
          <div className=" w-full">
            <img className=" w-full max-h-96" src={img} alt="Ad" />
          </div>
          <section className=" items-start justify-center flex-wrap h-fit flex md:w-[300px]">
            <button onClick={deleteStoreHandeller} className="deleteBtn">
              حذف الاعلان
            </button>
            {/* <button onClick={() => setEdit(!edit)} className="mainBtn">
              {edit ? "الغاء" : " تعديل الاعلان"}
            </button> */}
          </section>
        </div>
      </Layout>
    </div>
  );
}
