import React, { useEffect, useState } from "react";
import useFetch from "../../utils/useGet";
import usePostJSON from "../../utils/usePostJson";
import usePutJSON from "../../utils/usePutJson";

export default function Polices() {
  const [text, setText] = useState("");
  const [id, setId] = useState("");
  const [edit, seEdit] = useState(false);

  const [reload, setReload] = useState(false);
  const { data } = useFetch(`admin/terms/all`, reload);
  const re = () => {
    setReload(!reload);
  };
  useEffect(() => {
    if (data) {
      if (!!data.content) {
        setText(data.content);
        setId(data.id);
      }
    }
  }, [data]);

  const { postData } = usePostJSON();
  const addHandeller = async () => {
    const data = {
      content: text,
    };

    const result = await postData(`admin/terms/create`, data);
    if (result) {
      re();
    }
  };

  const { putData } = usePutJSON();
  const editHandeller = async () => {
    const data = {
      content: text,
    };

    const result = await putData(`admin/terms/update/${id}`, data);
    if (result) {
      await re();
      seEdit(false);
    }
  };

  return (
    <div className=" px-4 md:px-6 lg:px-8">
      <h1 className="tiltle text-center">الشروط والاحكام</h1>
      {edit ? (
        <>
          <textarea
            className="input2 h-fit"
            rows={10}
            onChange={(e) => setText(e.target.value)}
            value={text}
          />
          <section className=" my-4 w-full text-center">
            <button onClick={editHandeller} className="mainBtn">
              تعديل
            </button>
          </section>
        </>
      ) : (
        <>
          {!!data && (
            <>
              {!data.content ? (
                <>
                  <textarea
                    className="input2 h-fit"
                    rows={10}
                    onChange={(e) => setText(e.target.value)}
                    value={text}
                  />
                  <section className=" my-4 w-full text-center">
                    <button onClick={addHandeller} className="mainBtn">
                      اضافة
                    </button>
                  </section>
                </>
              ) : (
                <>
                  <p className=" leading-[40px]  my-6 text-[20px] font-[700] text-black relais ">
                    {text}
                  </p>
                  <section className=" my-4 w-full text-center">
                    <button onClick={() => seEdit(true)} className="mainBtn">
                      تعديل الشروط والاحكام
                    </button>
                  </section>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
