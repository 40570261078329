import React, { useState } from "react";
import usePutJSON from "../../utils/usePutJson";
import Cookies from "js-cookie";

export default function Password() {
  const [sucsses, setSucsses] = useState(false);
  const [info, setInfo] = useState({
    oldPassword: "",
    newPassword: "",
    reNewPassword: "",
  });

  const { oldPassword, newPassword, reNewPassword } = info;
  const handleInfoChange = (e) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };

  const id = Cookies.get("id");
  const { putData } = usePutJSON();
  const editHandeller = async () => {
    const data = {
      old_password: oldPassword,
      new_password: newPassword,
      new_password_confirmation: reNewPassword,
    };

    const result = await putData(`admin/settings/${id}/updatePassword`, data);
    if (result) {
      setInfo({
        oldPassword: "",
        newPassword: "",
        reNewPassword: "",
      });
      setSucsses(true);
      setTimeout(() => {
        setSucsses(false);
      }, 2000);
    }
  };
  return (
    <div>
      <h1 className="tiltle text-center">كلمة المرور</h1>
      <section //personal INFO
        className=" px-4 md:px-20 my-8 md:flex flex-wrap items-center justify-center md:justify-between"
      >
        <div //old
          className=" my-2"
        >
          <h4 className=" inputTitle">كلمة المرور القديمة</h4>
          <input
            name="oldPassword"
            value={oldPassword}
            onChange={handleInfoChange}
            className=" input"
            type="password"
            placeholder="كلمة المرور القديمة"
          />
        </div>
        <div //new
          className=" my-2"
        >
          <h4 className=" inputTitle">كلمة المرور الجديدة</h4>
          <input
            name="newPassword"
            value={newPassword}
            onChange={handleInfoChange}
            className=" input"
            type="password"
            placeholder="كلمة المرور الجديدة"
          />
        </div>
        <div //re
          className=" my-2"
        >
          <h4 className=" inputTitle">اعادة كتابة كلمة المرور </h4>
          <input
            name="reNewPassword"
            value={reNewPassword}
            onChange={handleInfoChange}
            className=" input"
            type="password"
            placeholder="اعادة كتابة كلمة المرور   "
          />
        </div>
      </section>
      <section className=" my-4 w-full text-center">
        <button onClick={editHandeller} className="mainBtn">
          تعيين كلمة المرور{" "}
        </button>
        {sucsses && (
          <section className=" p-3 bg-green-800 rounded-md sh-2 w-fit text-white mx-auto my-6 text-lg">
            تم تغير البيانات بنجاح
          </section>
        )}
      </section>
    </div>
  );
}
