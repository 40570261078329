import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import Store from "../../components/pages/Store";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CoupsStore from "./Coups";
import useFetch from "../../utils/useGet";
import useDelete from "../../utils/useDelete";
import usePost from "../../utils/usePost";
import imgPlaceholder from "../../placeholder-image.webp";

const initData = {
  img: imgPlaceholder,
  name: "",
  date: "",
  phone: "",
  desc: "",
  link: "",
  order: "",
  featured_images: ["", "", ""],
};

export default function StroeShow() {
  const searchPaeam = useLocation().search;
  const id = new URLSearchParams(searchPaeam).get("store");

  const [dataHead, setDataHead] = useState(initData);
  const { img, name, date, phone, desc, link, order, featured_images } =
    dataHead;
  const [reload, setRelaod] = useState(false);
  const { data } = useFetch(`admin/stores/show/${id}`, reload);
  const nvigate = useNavigate();
  const { postData } = usePost();
  const { deleteData } = useDelete();

  useEffect(() => {
    if (data) {
      let newFeatImgs = ["", "", ""];
      const feats = JSON.parse(data.featured_images);

      if (feats !== null && feats?.length !== 0) {
        feats.map((img, i) => {
          const num = Number(img.split("_").pop().match(/\d+/)[0]);
          newFeatImgs[num] = img;
        });
      }

      setDataHead({
        ...dataHead,
        img: `${process.env.REACT_APP_URL}/${data.image_path}`,
        name: data.name,
        date: data.created_at.substring(0, 10),
        phone: data.image_path,
        desc: data.description,
        link: data.image_path,
        order: data.order_store,
        featured_images: newFeatImgs,
      });
      // setData(data.stores);
      // setForSearch(data.stores);
    }
  }, [data]);

  const deleteStoreHandeller = async () => {
    let res = await deleteData(`admin/stores/delete/${id}`);

    if (res) {
      nvigate("/shops");
    }
  };

  const re = () => {
    setRelaod(!reload);
  };

  const editOrder = async () => {
    const formdata = new FormData();
    formdata.append("order_store", order);
    const result = await postData(`admin/stores/update/${id}`, formdata);
    if (result) {
      re();
    }
  };

  const handleFeatImgAdd = async (e, i) => {
    const newImg = e.target.files[0];
    let newFeats = [];

    setDataHead((prev) => {
      newFeats = prev.featured_images;
      newFeats[i] = newImg;

      return {
        ...prev,
        featured_images: newFeats,
      };
    });
  };
  const handleFeatImgRemove = async (i) => {
    setDataHead((prev) => {
      const newFeats = [...prev.featured_images];
      newFeats[i] = "";

      return {
        ...prev,
        featured_images: newFeats,
      };
    });
  };

  const handleFeatsSaveToServer = async () => {
    const formdata = new FormData();
    featured_images.map((img, i) => {
      formdata.append(`featured_images[${i}]`, img);
    });
    // [x] add and edit feat images here
    const result = await postData(`admin/stores/update/${id}`, formdata);
    result && re();
  };

  return (
    <div>
      <Layout title={"تفاصيل المتجر"}>
        <form className="my-6 flex flex-col items-center gap-y-4 mb-16">
          <div className="flex justify-between w-full px-4 container max-w-xl">
            {featured_images.map((src, i) => {
              return (
                <div
                  key={src + name + i}
                  className="flex flex-col gap-2 justify-center items-center"
                >
                  <label htmlFor={`file-${i}`} className="cursor-pointer">
                    <img
                      className="size-24 lg:size-32 rounded-xl shadow-md"
                      src={
                        src
                          ? typeof src === "object"
                            ? URL.createObjectURL(src)
                            : `${process.env.REACT_APP_URL}/${src}`
                          : imgPlaceholder
                      }
                      alt={`${name} store feature image -${i + 1}`}
                    />

                    {!src && (
                      <input
                        name={`feat-img-${i}`}
                        type="file"
                        id={`file-${i}`}
                        className="hidden"
                        onChange={(e) => handleFeatImgAdd(e, i)}
                      ></input>
                    )}
                  </label>

                  <button
                    onClick={(e) => handleFeatImgRemove(i)}
                    type="button"
                    className="text-red-500"
                  >
                    حذف
                    <i className="fa-solid fa-trash ms-2"></i>
                  </button>
                </div>
              );
            })}
          </div>

          <button
            type="button"
            onClick={handleFeatsSaveToServer}
            className="mainBtn"
          >
            حفظ الصور
          </button>
        </form>

        <div className=" md:flex">
          <div className=" w-full">
            <Store
              link={link}
              name={name}
              desc={desc}
              img={img}
              last={date}
              phone={phone}
            />
            <section className="flex flex-wrap items-center justify-center sm:justify-start gap-2">
              <h1 className=" m-3 text-3xl font-bold ">الترتيب : </h1>
              <input
                className=" text-center bg-[white]/0  outline-none border p-2 text-lg rounded-md"
                type="number"
                value={+order}
                onChange={(e) =>
                  setDataHead({ ...dataHead, order: e.target.value })
                }
              />
              <button onClick={editOrder} className="mainBtn">
                تعديل الترتيب
              </button>
            </section>
          </div>
          <section className=" items-start justify-center flex-wrap h-fit flex md:w-[300px]">
            <button onClick={deleteStoreHandeller} className="deleteBtn">
              حذف المتجر
            </button>
            {/* <button onClick={() => setEdit(!edit)} className="mainBtn">
              {edit ? "الغاء" : " تعديل المتجر"}
            </button> */}
          </section>
        </div>
        <div>
          <hr className=" my-6"></hr>
          <div className=" flex justify-center sm:justify-end">
            <Link to="/add-coupon">
              <button className=" mainBtn ">اضافة كوبون</button>
            </Link>
          </div>

          {data && <CoupsStore allData={data.coupons} reload={re} />}
        </div>
      </Layout>
    </div>
  );
}
