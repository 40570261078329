import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function DataTable({ allData }) {
  const [filter, setFilter] = useState({
    time: "oldest",
    search: "",
  });
  const { search, time } = filter;

  const handelChange = (e) => {
    const { value, name } = e.target;

    setFilter({ ...filter, [name]: value });
  };
  const [openSearch, setOpenSearch] = useState(false);

  const [data, setData] = useState([]);
  const [forSearch, setForSearch] = useState(allData);

  useEffect(() => {
    setForSearch(allData);
    setData(allData);
  }, [allData]);

  useEffect(() => {
    let searched = forSearch;
    if (search) {
      searched = searched.filter((e) => e.name.includes(search));
    }
    if (time === "newest") {
      searched = [...searched].reverse();
    }
    setData(searched);
  }, [filter, forSearch]);

  return (
    <div className=" p-2">
      {/* filtering */}
      <section className=" flex flex-wrap items-center justify-between bg-[#F5F6FA] w-full rounded-2xl px-2 py-5">
        <h1 className=" tiltle">المنتجات</h1>
        <section className=" my-2 hidden md:flex items-center">
          <div className="outline-none flex  relative  mx-2 text-[#BFBFBF] bg-white rounded-lg p-2 h-[60px] w-fit ">
            <div className=" w-fit h-full flex items-center">
              <i className="fa-solid fa-magnifying-glass text-lg "></i>
            </div>
            <input
              value={search}
              name="search"
              onChange={handelChange}
              type="text"
              placeholder="بحث"
              className=" outline-none px-2"
            />
          </div>
          <section className="text-[#4B176A] flex items-center justify-center bg-white rounded-lg p-2 h-[60px] w-[60px]">
            <i className="fa-solid fa-sliders text-xl"></i>
          </section>
        </section>
        <button
          onClick={() => setOpenSearch(!openSearch)}
          className="md:hidden my-2 flex"
        >
          <section className="text-[#4B176A] flex items-center justify-center bg-white rounded-lg p-2 h-[60px] w-[60px]">
            <i className="fa-solid fa-sliders text-xl"></i>
          </section>
        </button>
        {openSearch && (
          <section className=" md:hidden my-2 flex  items-center">
            <select
              name="time"
              onChange={handelChange}
              className="outline-none mx-2 text-[#BFBFBF] bg-white rounded-lg p-2 h-[60px] w-24 "
              value={time}
            >
              <option className=" p-2 " value={"newest"}>
                الاحدث
              </option>
              <option value={"oldest"}>الاقدم</option>
            </select>
            <div className="outline-none flex  relative  mx-2 text-[#BFBFBF] bg-white rounded-lg p-2 h-[60px] w-fit ">
              <div className=" w-fit h-full flex items-center">
                <i className="fa-solid fa-magnifying-glass text-lg "></i>
              </div>
              <input
                value={search}
                name="search"
                onChange={handelChange}
                type="text"
                placeholder="بحث"
                className=" outline-none px-2 w-48"
              />
            </div>
          </section>
        )}
      </section>
      <table>
        <thead>
          <tr>
            <th>اسم المنتج</th>
            <th>وصف المنتج</th>
            <th>تاريخ الانشاء</th>
            <th>الاجراء</th>
          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr key={product.title + product.id}>
              <td className="whitespace-nowrap">{product.title}</td>
              <td>{product.description}</td>
              <td className="min-w-20 md:min-w-28">
                {product.created_at.substring(0, 10)}
              </td>
              <td className="text-sm">
                <Link
                  className="flex items-center justify-center gap-2"
                  to={`/product?product=${product.id}`}
                >
                  <span className="hidden md:flex">عرض</span>
                  <i className="fa-solid fa-eye"></i>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
