import React from "react";

export default function Tab({ tab, handleChange, value, text }) {
  return (
    <div>
      <button
        className={` text-base py-2 font-[700] mx-3   ${
          tab == value ? "text-[#0D8F75]" : "text-[#BFBFBF]"
        } ${tab == value && "border-b-2 border-[#0D8F75]"}`}
        onClick={handleChange}
        name={value}
      >
        {text}
      </button>
    </div>
  );
}
