import React, { useContext, useState } from "react";
import LoginLayout from "../../components/Login/LoginLayout";
import Loader from "../../components/Loader/Loader";
import { isLogged } from "../../utils/inStore";
import Cookies from "js-cookie";

export default function Login() {
  const [err, setErr] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    mail: "",
    password: "",
    showPassword: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const toggleShowPassword = (e) => {
    e.preventDefault();
    setInputs({ ...inputs, showPassword: !inputs.showPassword });
  };

  const { mail, password, showPassword } = inputs;

  const setLogg = useContext(isLogged).setLogged;

  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: mail,
      password: password,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_URL}/api/login`, requestOptions).then(
      (res) => {
        setIsLoading(false);

        if (res.status === 200) {
          res.json().then((data) => {
            Cookies.set("token", data.token);
            Cookies.set("id", data.user.id);
            setLogg(true);
          });
        } else {
          setErr(true);
         
        }
      }
    );
  };
  return (
    <div className=" ">
      <LoginLayout>
        {isLoding && <Loader />}
        <div>
          <h1 className="text-[#32363B] text-2xl font-bold">تسجيل الدخول</h1>
          <h4 className="text-[#CCCCCC] text-sm font-normal">
            قم بتسجيل الدخول
          </h4>
          <form className="my-4">
            <div className="text-[#0D8F75] my-4">
              <h4 className="text-sm font-normal">البريد الالكتروني</h4>
              <input
                name="mail"
                value={mail}
                onChange={handleInputChange}
                className="border rounded-2xl outline-none p-2 text-base mt-2 w-4/5"
                required
                placeholder="admin@gmail.com"
                type="email"
              />
            </div>
            <div className="text-[#0D8F75] my-4">
              <h4 className="text-sm font-normal">كلمة السر</h4>
              <div className="border rounded-2xl overflow-hidden outline-none text-base mt-2 w-4/5 flex items-center">
                <input
                  name="password"
                  value={password}
                  onChange={handleInputChange}
                  className="outline-none p-2 w-full"
                  required
                  placeholder="**************"
                  type={showPassword ? "text" : "password"}
                />
                <button
                  className="p-2"
                  onClick={toggleShowPassword}
                  aria-label="Toggle password visibility"
                >
                  {showPassword ? (
                    <i className="fa-solid fa-eye-slash"></i>
                  ) : (
                    <i className="fa-solid fa-eye"></i>
                  )}
                </button>
              </div>
            </div>
            <div className="w-4/5 flex items-center justify-between">
              <label className="flex items-center text-[#7A86A1] text-xs">
                <input
                  type="checkbox"
                  id="remember"
                  name="remember"
                  className="border rounded-md mr-1"
                />
                تذكرني
              </label>
              {/* <Link className="text-[#4F6576] text-xs" to={"/forget-password"}>
                هل نسيت كلمة المرور؟
              </Link> */}
            </div>
            <button
              onClick={handleLogin}
              type="submit"
              className="my-3 p-2 bg-[#0D8F75] text-white w-4/5 rounded-md text-lg"
            >
              تسجيل الدخول
            </button>
            {err && (
              <section className=" my-2 bg-red-500/80 rounded-md w-4/5 p-2 text-sm text-white flex items-center">
                <section className=" bg-white text-red-500/80 ml-2 w-6 h-6 rounded-full flex items-center justify-center">
                  <i className="fa-solid fa-exclamation"></i>
                </section>
                كلمة السر او البريد الالكتروني خطأ
              </section>
            )}
          </form>
        </div>
      </LoginLayout>
    </div>
  );
}
