import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Message from "../../components/pages/Message";
import useFetch from "../../utils/useGet";
import useDelete from "../../utils/useDelete";

export default function Dep() {
  const searchPaeam = useLocation().search;
  const id = new URLSearchParams(searchPaeam).get("dep");

  const [dataHead, setDataHead] = useState({
    img: "https://th.bing.com/th/id/OIP.9hetfdrodOfI9KzE_g_dDAAAAA?rs=1&pid=ImgDetMain",
    name: "",
    date: "",
    timehead: "",
  });

  const { img, name, date, timehead } = dataHead;

  const [reload, setRelaod] = useState(false);
  const { data } = useFetch(`admin/sections/show/${id}`, reload);
  // console.log(data);
  const [allData, setData] = useState([]);

  const [forSearch, setForSearch] = useState([]);
  useEffect(() => {
    if (data) {
      setDataHead({
        img: `${process.env.REACT_APP_URL}/${data.image_path}`,
        name: data.name,
        date: data.created_at.substring(0, 10),
        timehead: data.created_at.substring(11, 16),
      });
      setData(data.stores);
      setForSearch(data.stores);
    }
  }, [data]);

  // Filtering

  const [filter, setFilter] = useState({
    time: "newest",
    search: "",
  });
  const { search, time } = filter;

  const handelChange = (e) => {
    const { value, name } = e.target;

    setFilter({ ...filter, [name]: value });
  };
  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    let searched = forSearch;
    if (search) {
      searched = searched.filter((e) => e.name.includes(search));
    }
    if (time === "newest") {
      searched = [...searched].reverse();
    }
    setData(searched);
  }, [filter, forSearch]);

  const [describtion, setDescribtion] = useState(null);

  // Delete
  const { deleteData } = useDelete();

  const deleteStoreHandeller = async (id) => {
    let res = await deleteData(`admin/stores/delete/${id}`);

    if (res) {
      setRelaod(!reload);
    }
  };

  const nvigate = useNavigate();

  const deleteDepartHandeller = async () => {
    let res = await deleteData(`admin/sections/delete/${id}`);

    if (res) {
      nvigate("/");
    }
  };

  return (
    <Layout title="تفاصيل القسم">
      <div className=" p-3">
        <section //Title
          className=" flex items-center justify-between rounded-xl p-6 bg-[#085646]"
        >
          <section className=" flex items-center">
            <img
              className="h-[80px] w-[80px] rounded-md border-[7px] mx-2 border-white"
              src={img}
              alt="department"
            />
            <section className=" mx-2 text-white">
              <h4 className=" mb-1 text-[20px] font-[500] text-white">
                {name}
              </h4>
              <h4 className=" text-xs font-[200]">
                <i className="fa-solid fa-calendar-days ml-1"></i> {date}
                <i className="fa-regular fa-clock mx-1"></i> {timehead}
              </h4>
            </section>
          </section>
          <section className="hidden md:flex items-center text-white">
            <button onClick={deleteDepartHandeller} className=" deleteBtn">
              حذف القسم
            </button>
          </section>
        </section>
        <section //Btns
          className=" my-5 flex flex-wrap justify-end"
        >
          <button
            onClick={deleteDepartHandeller}
            className="deleteBtn md:hidden"
          >
            حذف القسم
          </button>
          <Link to="/add-store">
            <button className="mainBtn">اضافة متجر جديد</button>
          </Link>
        </section>

        <section //filtering
          className=" flex flex-wrap items-center justify-between bg-[#F5F6FA] w-full rounded-2xl px-2 py-5"
        >
          <h1 className=" tiltle">المتاجر</h1>
          <section className=" my-2 hidden md:flex items-center">
            <h4 className="text-[16px] font-[500] text-[#6D4E1F]">
              مصنفة بواسطة :
            </h4>
            <select
              name="time"
              onChange={handelChange}
              className="outline-none mx-2 text-[#BFBFBF] bg-white rounded-lg p-2 h-[60px] w-48 "
              value={time}
            >
              <option className=" p-2 " value={"newest"}>
                الاحدث
              </option>
              <option value={"oldest"}>الاقدم</option>
            </select>
            <div className="outline-none flex  relative  mx-2 text-[#BFBFBF] bg-white rounded-lg p-2 h-[60px] w-fit ">
              <div className=" w-fit h-full flex items-center">
                <i className="fa-solid fa-magnifying-glass text-lg "></i>
              </div>
              <input
                value={search}
                name="search"
                onChange={handelChange}
                type="text"
                placeholder="بحث"
                className=" outline-none px-2"
              />
            </div>
            <section className="text-[#4B176A] flex items-center justify-center bg-white rounded-lg p-2 h-[60px] w-[60px]">
              <i className="fa-solid fa-sliders text-xl"></i>
            </section>
          </section>
          <button
            onClick={() => setOpenSearch(!openSearch)}
            className="md:hidden my-2 flex"
          >
            <section className="text-[#4B176A] flex items-center justify-center bg-white rounded-lg p-2 h-[60px] w-[60px]">
              <i className="fa-solid fa-sliders text-xl"></i>
            </section>
          </button>
          {openSearch && (
            <section className=" md:hidden my-2 flex  items-center">
              <select
                name="time"
                onChange={handelChange}
                className="outline-none mx-2 text-[#BFBFBF] bg-white rounded-lg p-2 h-[60px] w-24 "
                value={time}
              >
                <option className=" p-2 " value={"newest"}>
                  الاحدث
                </option>
                <option value={"oldest"}>الاقدم</option>
              </select>
              <div className="outline-none flex  relative  mx-2 text-[#BFBFBF] bg-white rounded-lg p-2 h-[60px] w-fit ">
                <div className=" w-fit h-full flex items-center">
                  <i className="fa-solid fa-magnifying-glass text-lg "></i>
                </div>
                <input
                  value={search}
                  name="search"
                  onChange={handelChange}
                  type="text"
                  placeholder="بحث"
                  className=" outline-none px-2 w-48"
                />
              </div>
            </section>
          )}
        </section>
        <table>
          <thead>
            <tr>
              <th>اسم المتجر</th>
              <th> وصف المتجر</th>
              <th> تاريخ الاضافة</th>
              {/* <th> عدد الكوبونات</th> */}
              <th>الاجراء</th>
            </tr>
          </thead>
          <tbody>
            {allData.map((e, indx) => {
              return (
                <tr key={indx}>
                  <td>{e.name}</td>
                  <td className="flex justify-center">
                    <button
                      onClick={() => setDescribtion(e.description)}
                      className="underline"
                    >
                      عرض الوصف
                    </button>
                  </td>
                  <td>{e.created_at.substring(0, 10)}</td>
                  {/* <td>{e.num} كود</td> */}
                  <td className=" flex justify-center gap-1 text-sm">
                    <Link
                      className=" flex items-center justify-center gap-1"
                      to={`/store?store=${e.id}`}
                    >
                      <span className=" hidden md:flex">عرض</span>{" "}
                      <i className="fa-solid fa-eye"></i>
                    </Link>
                    <button
                      onClick={() => deleteStoreHandeller(e.id)}
                      className=" text-red-600 flex items-center justify-center gap-1"
                    >
                      <span className=" hidden md:flex">حذف</span>{" "}
                      <i className="fa-solid fa-trash"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {describtion && (
        <Message
          content={describtion}
          close={() => setDescribtion(null)}
          title="وصف المتجر"
        />
      )}
    </Layout>
  );
}
