import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import useFetch from "../../utils/useGet";
import { useLocation, useNavigate } from "react-router";
import useDelete from "../../utils/useDelete";

export default function Coupon() {
  const searchPaeam = useLocation().search;
  const id = new URLSearchParams(searchPaeam).get("coupon");
  let [dataHead, setDataHead] = useState({
    img: "https://th.bing.com/th/id/OIP.9hetfdrodOfI9KzE_g_dDAAAAA?rs=1&pid=ImgDetMain",
    name: "",
    code: "",
    desc: "",
    store: "",
    percnt: "",
    link: "",
  });
  const { img, name, code, desc, store, percnt, link } = dataHead;
  const [reload, setRelaod] = useState(false);
  const { data } = useFetch(`admin/coupons/show/${id}`, reload);
  const re = () => {
    setRelaod(!reload);
  };
  useEffect(() => {
    if (data) {
      setDataHead({
        img: `${process.env.REACT_APP_URL}/${data.image_path}`,
        name: data.name,
        code: data.code,
        desc: data.description,
        store: data.store ? data.store.name : "",
        percnt: data.discount,
        link: data.link,
      });
      // setData(data.stores);
      // setForSearch(data.stores);
    }
  }, [data]);

  const { deleteData } = useDelete();

  const nvigate = useNavigate();

  const deleteStoreHandeller = async () => {
    let res = await deleteData(`admin/coupons/delete/${id}`);

    if (res) {
      nvigate("/coupons");
    }
  };
  return (
    <div>
      <Layout title={"تفاصيل الكوبون"}>
        <div className=" md:flex">
          <div className=" p-2 w-full">
            <div className=" w-full sh-2">
              <div className="  border-[#E5E5E5] overflow-hidden rounded-xl sh-2">
                {/* <section className=" flex items-center justify-between rounded-xl p-6 bg-[#085646]">
                  <section className=" flex items-center">
                    <img
                      className="h-[80px] w-[80px] rounded-md border-[7px] mx-2 border-white"
                      src={img}
                      alt="user"
                    />
                  </section>
                </section> */}
                <section className=" w-full flex items-center justify-start flex-wrap p-6">
                  <div className=" my-4 w-full md:w-1/2 lg:w-1/3">
                    <h1 className=" subTitle">اسم الكوبون</h1>
                    <h1 className=" subtext">{name}</h1>
                  </div>
                  <div className=" my-4 w-full md:w-1/2 lg:w-1/3">
                    <h1 className=" subTitle">كود الخصم</h1>
                    <h1 className=" subtext">{code}</h1>
                  </div>
                  <div className=" my-4 w-full md:w-1/2 lg:w-1/3">
                    <h1 className=" subTitle">وصف الكوبون</h1>
                    <h1 className=" subtext">{desc}</h1>
                  </div>
                  <div className=" my-4 w-full md:w-1/2 lg:w-1/3">
                    <h1 className=" subTitle">المتجر التابع له</h1>
                    <h1 className=" subtext">{store}</h1>
                  </div>
                  <div className=" my-4 w-full md:w-1/2 lg:w-1/3">
                    <h1 className=" subTitle">نسبة الخصم</h1>
                    <h1 className=" subtext">{percnt}</h1>
                  </div>
                  <div className=" my-4 w-full md:w-1/2 lg:w-1/3">
                    <h1 className=" subTitle">رابط الكوبون</h1>
                    <h1 className=" subtext">{link}</h1>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <section className=" items-start justify-center flex-wrap h-fit flex md:w-[300px]">
            <button onClick={deleteStoreHandeller} className="deleteBtn">حذف الكوبون</button>
            {/* <button className="mainBtn">تعديل الكوبون</button> */}
          </section>
        </div>
      </Layout>
    </div>
  );
}
