import { useState } from "react";
import Layout from "../../components/Layout/Layout";
import usePost from "../../utils/usePost";
import { useNavigate } from "react-router";

const initData = {
  name: "",
  daitails: "",
  file: null,
  link: "",
};

const allProducts = [
  { name: "111", id: 11 },
  { name: "112", id: 12 },
  { name: "113", id: 13 },
  { name: "114", id: 14 },
  { name: "115", id: 15 },
];

export default function AddProduct() {
  const [searchQuery, setSearchQuery] = useState("");
  const [info, setInfo] = useState(initData);
  const [error, setError] = useState("");
  const { name, daitails, file, link } = info;

  const handleInfoChange = (e) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };

  const getSelectedUserNames = (selectedIds) => {
    return allProducts
      .filter((user) => selectedIds.includes(user.id))
      .map((user) => user.name)
      .join(", ");
  };

  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filtered users based on search query
  const filteredUsers = allProducts.filter((user) =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const { postData } = usePost();
  const navigate = useNavigate();

  const addHandeller = async () => {
    if (!name || !daitails || !file || !link) {
      setError("كل البيانات مطلوبة");
      return;
    }
    if (
      link &&
      !/^(https?:\/\/)([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(
        link
      )
    ) {
      setError("يجب  عليك اضافة رابط صحيح (يجب ان يبدا بـ https://)");
      return;
    }

    setError("");
    const formdata = new FormData();
    formdata.append("title", name);
    formdata.append("description", daitails);
    formdata.append("image_path", file);
    formdata.append("url", link);

    console.log(name, daitails, file, link);

    const result = await postData(`admin/products/create`, formdata);
    result && navigate("/products");
  };

  return (
    <Layout title={"اضافة منتج"}>
      <section className="px-4 md:px-24 lg:px-48 my-8 space-y-6">
        <div>
          <h4 className="inputTitle">اسم المنتج </h4>
          <input
            name="name"
            value={name}
            onChange={handleInfoChange}
            className="input"
            type="text"
            placeholder="اكتب اسم المنتج..."
          />
        </div>
        <div>
          <h4 className="inputTitle"> وصف المنتج </h4>
          <textarea
            name="daitails"
            value={daitails}
            onChange={handleInfoChange}
            className="input2"
            type="text"
            rows={5}
            placeholder="اكتب وصف للمنتج..."
          />
        </div>

        <div>
          <h4 className="inputTitle">رفع صورة للمنتج</h4>
          {file ? (
            // if file or else
            <div className="relative">
              {/* Display Image */}
              <img
                alt="cover"
                className="max-w-full max-h-48 border rounded-md shadow-md mx-auto"
                src={URL.createObjectURL(file)}
              />
            </div>
          ) : (
            <div className="input2 h-[150px] flex items-center justify-center">
              {/* Upload Image */}
              <section className="h-full w-full">
                <label
                  className="flex flex-col items-center justify-center gap-4 cursor-pointer h-full w-full"
                  htmlFor="file"
                >
                  <span className="w-full flex justify-center">
                    <i className="fa-solid fa-arrow-up-from-bracket w-4 text-[#6D4E1F] text-[18px] text-center"></i>
                  </span>
                  <h4 className="text-[#A3AED0] text-[12px]">
                    قم برفع ملفات PNG , JPG
                  </h4>
                </label>
                <input
                  required
                  type="file"
                  id="file"
                  onChange={(e) => {
                    setInfo({ ...info, file: e.target.files[0] });
                  }}
                  style={{ display: "none" }}
                  accept="image/*"
                />
              </section>
            </div>
          )}
        </div>

        <div>
          <h4 className="inputTitle">رابط المنتج </h4>
          <input
            name="link"
            value={link}
            onChange={handleInfoChange}
            className="input !w-full"
            dir="ltr"
            type="text"
            placeholder="اكتب رابط المنتج..."
          />
        </div>

        <div className="flex justify-center pt-4">
          <button onClick={addHandeller} className="mainBtn">
            اضافة منتج
          </button>
        </div>

        <p
          className="text-red-500 text-center duration-300 transition-opacity"
          style={{ opacity: error ? 1 : 0 }}
        >
          {error}
        </p>
      </section>
    </Layout>
  );
}
