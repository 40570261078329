import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Link, useLocation } from "react-router-dom";
import useFetch from "../../utils/useGet";

export default function MessageShow() {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const [info, setInfo] = useState({
    title: "",
    deatails: "",
  });
  const { title, deatails } = info;
  const { data } = useFetch(`admin/support/show/${id}`);
  useEffect(() => {
    if (data) {
      setInfo({
        title: data.title,
        deatails: data.details,
      });
      // setData(data.stores);
      // setForSearch(data.stores);
    }
  }, [data]);
  return (
    <Layout title={"تفاصيل المشكلة"}>
      <div className="px-4 md:px-24 lg:px-48 my-8">
        <div className=" sh-2 bg-white rounded-xl py-12 px-8 md:px-20 lg:px-40">
          <h1 className="tiltle text-center">تفاصيل المشكلة</h1>
          <section className=" my-8">
            <h4 className=" subTitle"> عنوان المشكلة </h4>
            <p className="subText">{title}</p>
          </section>
          <section className=" my-8">
            <h4 className=" subTitle"> تفاصيل المشكلة </h4>
            <p className="subText">{deatails}</p>
          </section>
          <section className=" text-center">
            <Link to={`/reply?id=${id}`}>
              <button className="mainBtn">الرد علي المشكلة</button>
            </Link>
          </section>
        </div>
      </div>
    </Layout>
  );
}
