import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import Status from "../../components/pages/Status";
import { Link } from "react-router-dom";
import Deps from "./Deps";
import useFetch from "../../utils/useGet";

export default function Main() {
  const [reload, setRelaod] = useState(false);
  const { data } = useFetch("admin/sections/all", reload);

  const re = () => {
    setRelaod(!reload)
  }

  return (
    <div>
      <Layout title="الاقسام">
        <section>
          <Status
            text={"جميع الاقسام"}
            num={data ? data.length : 0}
            colour={"fffff"}
          >
            <section
              className={` mx-2 rounded-2xl flex items-center justify-center h-[45px] w-[45px] bg-[#D680FF]/20`}
            >
              <i className={`fa-solid fa-grip text-[20px] text-[#D680FF]`}></i>
            </section>
          </Status>
          <div className=" flex justify-end">
            <Link to="/add-department">
              <button className=" mainBtn ">اضافة قسم جديد</button>
            </Link>
          </div>
        </section>
        {data && <Deps allData={data} reload={re} />}
      </Layout>
    </div>
  );
}
