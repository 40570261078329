import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import useFetch from "../../utils/useGet";
import usePostJSON from "../../utils/usePostJson";
import { useNavigate } from "react-router";

export default function AddCoupon() {
  const [info, setInfo] = useState({
    name: "",
    code: "",
    percent: "",
    link: "",
    daitails: "",
    stores: [],
    store: "",
  });

  const { name, code, percent, link, daitails, stores, store } = info;
  const handleInfoChange = (e) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };

  const getSelectedUserNames = (selectedIds) => {
    return allStores
      .filter((user) => selectedIds.includes(user.id))
      .map((user) => user.name)
      .join(", ");
  };

  const [searchQuery, setSearchQuery] = useState("");

  const allStores = [
    { name: "111", id: 11 },
    { name: "112", id: 12 },
    { name: "113", id: 13 },
    { name: "114", id: 14 },
    { name: "115", id: 15 },
  ];

  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filtered users based on search query
  const filteredUsers = allStores.filter((user) =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const deps = useFetch("admin/stores/all").data;
  const { postData } = usePostJSON();
  const navigate = useNavigate();

  const addHandeller = async () => {
    const data = {
      code: code,
      name: name,
      discount: percent,
      description: daitails,
      link: link,
      store_id: store,
    };

    const result = await postData(`admin/coupons/create`, data);
    if (result) {
      navigate("/coupons");
    }
  };
  return (
    <div>
      <Layout title="اضافة كوبون">
        <section //personal INFO
          className=" px-4 md:px-24 lg:px-48 my-8 md:flex flex-wrap items-center justify-center md:justify-between"
        >
          <div //name
            className=" my-2"
          >
            <h4 className=" inputTitle">اسم الكوبون</h4>
            <input
              name="name"
              value={name}
              onChange={handleInfoChange}
              className=" input"
              type="text"
              placeholder="اسم الكوبون"
            />
          </div>
          <div //code
            className=" my-2"
          >
            <h4 className=" inputTitle">كود الخصم</h4>
            <input
              name="code"
              value={code}
              onChange={handleInfoChange}
              className=" input"
              type="text"
              placeholder="كود الخصم"
            />
          </div>
          <div //percent
            className=" my-2"
          >
            <h4 className=" inputTitle">نسبة الخصم</h4>
            <input
              name="percent"
              value={percent}
              onChange={handleInfoChange}
              className=" input"
              type="number"
              placeholder="نسبة الخصم"
            />
          </div>
          <div //link
            className=" my-2"
          >
            <h4 className=" inputTitle">رابط الكوبون</h4>
            <input
              name="link"
              value={link}
              onChange={handleInfoChange}
              className=" input"
              type="text"
              placeholder="رابط الكوبون"
            />
          </div>
          {/* <div //stores
            className=" my-2"
          >
            <h4 className=" inputTitle">المتاجر التابعة للكبون</h4>
            <FormControl sx={{ width: "100%" }}>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={stores}
                className=" input"
                name="stores"
                onChange={handleInfoChange}
                // input={<OutlinedInput label="المستخدمين" />}
                renderValue={(selected) => getSelectedUserNames(selected)}
                // MenuProps={MenuProps}
              >
                <Box sx={{ padding: 1 }}>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Box>
                {filteredUsers.map((name) => (
                  <MenuItem key={name} value={name.id}>
                    <Checkbox checked={stores.indexOf(name.id) > -1} />
                    <ListItemText primary={name.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div> */}
          <div //stores
            className=" my-2"
          >
            <h4 className=" inputTitle">المتجر </h4>
            <select
              name="store"
              value={store}
              onChange={handleInfoChange}
              className=" input"
              type="text"
              placeholder=" اسم المتجر"
            >
              <option hidden selected>
                المتجر
              </option>
              {deps &&
                deps.map((e) => (
                  <option key={e.id} value={e.id}>
                    {e.name}
                  </option>
                ))}
            </select>
          </div>
        </section>
        <section className="px-4 md:px-24 lg:px-48">
          <div //daitails
            className=" my-2  w-full"
          >
            <h4 className=" inputTitle"> وصف الكوبون </h4>
            <textarea
              name="daitails"
              value={daitails}
              onChange={handleInfoChange}
              className="  input2"
              type="text"
              rows={5}
              placeholder="وصف الكوبون"
            />
          </div>
        </section>
        <section className=" my-4 w-full text-center">
          <button onClick={addHandeller} className="mainBtn">
            اضافة كوبون
          </button>
        </section>
      </Layout>
    </div>
  );
}
