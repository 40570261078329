import { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import Store from "../../components/pages/Store";
import { useLocation, useNavigate } from "react-router-dom";
import useFetch from "../../utils/useGet";
import useDelete from "../../utils/useDelete";

const initData = {
  img: "https://th.bing.com/th/id/OIP.9hetfdrodOfI9KzE_g_dDAAAAA?rs=1&pid=ImgDetMain",
  name: "",
  date: "",
  phone: "",
  desc: "",
  link: "",
  order: "",
};

export default function ProductPage() {
  const searchPaeam = useLocation().search;
  const id = new URLSearchParams(searchPaeam).get("product");

  const [dataHead, setDataHead] = useState(initData);
  const { img, title, date, phone, desc, link, order } = dataHead;
  const [reload, setRelaod] = useState(false);
  const { data } = useFetch(`admin/products/show/${id}`, reload);

  // const re = () => {
  //   setRelaod(!reload);
  // };
  useEffect(() => {
    if (data) {
      setDataHead({
        img: `${process.env.REACT_APP_URL}/${data.image_path}`,
        title: data.title,
        date: data.created_at.substring(0, 10),
        phone: data.image_path,
        desc: data.description,
        link: data.image_path,
        order: data.order_store,
      });
      // setData(data.stores);
      // setForSearch(data.stores);
    }
  }, [data]);

  const { deleteData } = useDelete();

  const nvigate = useNavigate();

  const deleteStoreHandeller = async () => {
    let res = await deleteData(`admin/products/delete/${id}`);

    res && nvigate("/products");
  };

  // const { postData } = usePost();

  // const editOrder = async () => {
  //   const formdata = new FormData();
  //   formdata.append("order_store", order);
  //   const result = await postData(`admin/stores/update/${id}`, formdata);
  //   if (result) {
  //     re();
  //   }
  // };

  return (
    <div>
      <Layout title={"تفاصيل المنتج"}>
        <div className=" md:flex">
          <div className=" w-full">
            <div className=" sh-2 m-2">
              <div className="  border-[#E5E5E5] overflow-hidden rounded-xl sh-2">
                <section className=" flex items-center justify-between rounded-xl p-6 bg-[#085646]">
                  <section className="flex items-center">
                    <img
                      className="h-[80px] w-[80px] rounded-md border-[7px] mx-2 border-white"
                      src={img}
                      alt="user"
                    />
                    <section className="mx-2 text-white">
                      <h4 className="text-[20px] font-[500] text-white">
                        {title}
                      </h4>
                    </section>
                  </section>
                </section>
                <section className="max-w-[900px] flex items-center justify-start flex-wrap p-6">
                  <div className="px-4 my-4 w-full md:w-1/2 lg:w-1/3">
                    <h1 className="subTitle">اسم المنتج</h1>
                    <h1 className="subtext">{title}</h1>
                  </div>
                  <div className="px-4 my-4 w-full md:w-1/2 lg:w-1/3">
                    <h1 className="subTitle">لينك المنتج</h1>
                    <h1 className="subtext">{link}</h1>
                  </div>
                  <div className="px-4 my-4 w-full md:w-1/2 lg:w-1/3">
                    <h1 className="subTitle">وصف المنتج</h1>
                    <h1 className="subtext">{desc}</h1>
                  </div>
                  <div className="px-4 my-4 w-full md:w-1/2 lg:w-1/3">
                    <h1 className="subTitle">تاريخ الانشاء</h1>
                    <h1 className="subtext">{date}</h1>
                  </div>
                  {/* <div className=" my-4 w-full md:w-1/2 lg:w-1/3">
            <h1 className=" subTitle">رقم التواصل:</h1>
            <h1 className=" subtext">{phone}</h1>
          </div> */}
                </section>
              </div>
            </div>
            {/* <section className=" flex items-center justify-start gap-2">
              <h1 className=" m-3 text-3xl font-bold ">الترتيب : </h1>
              <input
                className=" text-center bg-[white]/0  outline-none border p-2 text-lg rounded-md"
                type="number"
                value={+order}
                onChange={(e) =>
                  setDataHead({ ...dataHead, order: e.target.value })
                }
              />
              <button onClick={editOrder} className="mainBtn">
                تعديل الترتيب
              </button>
            </section> */}
          </div>
          <section className=" items-start justify-center flex-wrap h-fit flex md:w-[300px]">
            <button onClick={deleteStoreHandeller} className="deleteBtn">
              حذف المنتج
            </button>
            {/* <button onClick={() => setEdit(!edit)} className="mainBtn">
              {edit ? "الغاء" : " تعديل المتجر"}
            </button> */}
          </section>
        </div>
      </Layout>
    </div>
  );
}
