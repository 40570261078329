import React from "react";
import Layout from "../../components/Layout/Layout";
import "../pages.css";

import Statuses from "./Statuses";
import Reqs from "./Reqs";

export default function Requests() {
  return (
    <Layout title={"الطلبات"}>
      <Statuses />
      <Reqs />
    </Layout>
  );
}
