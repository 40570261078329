import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import Status from "../../components/pages/Status";
import { Link } from "react-router-dom";
import Custmrs from "./Custmrs";
import useFetch from "../../utils/useGet";
export default function Customers() {
  const [reload, setRelaod] = useState(false);
  const { data } = useFetch("admin/customers/all", reload);

  const re = () => {
    setRelaod(!reload);
  };
  return (
    <div>
      <Layout title="العملاء">
        <section>
          <Status
            text={"جميع العملاء"}
            num={data ? data.length : 0}
            colour={"fffff"}
          >
            <section
              className={` mx-2 rounded-2xl flex items-center justify-center h-[45px] w-[45px] bg-[#8AFF80]/20`}
            >
              <i className={`fa-solid fa-users text-[20px] text-[#8AFF80]`}></i>
            </section>
          </Status>
          <div className=" flex justify-end">
            <Link to="/add-customer">
              <button className=" mainBtn ">اضافة عميل</button>
            </Link>
          </div>
        </section>

        {data && <Custmrs allData={data} reload={re} />}
      </Layout>
    </div>
  );
}
