import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router";

import SideBar from "./SideBar";
import NavBar from "./NavBar";
import { isLogged } from "../../utils/inStore";
import { headTitle } from "../../utils/titleStore";
import SideBarTop from "./SideBarTop";

export default function LayoutMain({ children }) {
  const title = useContext(headTitle).title;
  const isLogIn = useContext(isLogged).logged;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogIn) {
      navigate("/");
    }
  }, [isLogIn, navigate]);

  const [open, setOpen] = useState(false);
  const toggel = () => {
    setOpen(!open);
  };

  if (isLogIn) {
    return (
      <div className=" bg-white">
        {" "}
        <div className=" max-w-full  rtl flex items-start justify-start h-screen overflow-hidden">
          <div className=" hidden lg:grid w-72">
            {" "}
            <SideBar />
          </div>

          <div className=" h-screen overflow-y-auto w-full">
            <section>
              <NavBar toggel={toggel} title={title} />
              <SideBarTop toggel={toggel} open={open} />
            </section>
            <div className=" max-h-full ">{children}</div>
          </div>
        </div>
      </div>
    );
  } else {
    return <>{children}</>;
  }
}
