import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import usePost from "../../utils/usePost";
import { useNavigate } from "react-router";

export default function AddAd() {
  const [info, setInfo] = useState({
    file: null,
    main: "1",
  });

  const { file, main } = info;

  const handleInfoChange = (e) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };

  // Add
  const { postData } = usePost();
  const navigate = useNavigate();
  const addHandeller = async () => {
    const formdata = new FormData();

    formdata.append("is_main", main);
    formdata.append("image", file);

    const result = await postData(`admin/advertisements/create`, formdata);
    if (result) {
      navigate("/ads");
    }
  };
  return (
    <div>
      <Layout title={"اضافة اعلان"}>
        <div className="px-4 md:px-24 lg:px-48 my-8">
          <div className=" sh-2 bg-white rounded-xl py-12 px-8 md:px-20 lg:px-40">
            <h1 className="tiltle text-center">اضافة اعلان جديد</h1>
            <div //image
              className=" my-2  w-full"
            >
              <h4 className=" inputTitle">رفع صورة الاعلان</h4>
              {file ? (
                // if file or else
                <div className="relative">
                  {/* Display Image */}
                  <img
                    alt="cover"
                    className="max-w-full max-h-48 border rounded-md shadow-md mx-auto"
                    src={URL.createObjectURL(file)}
                  />
                </div>
              ) : (
                <div className="input2 h-[100px] flex items-center justify-center">
                  {/* Upload Image */}
                  <section>
                    <label
                      className="po cursor-pointer h-full w-full"
                      htmlFor="file"
                    >
                      <span className="w-full flex justify-center">
                        <i className="fa-solid fa-arrow-up-from-bracket w-4 text-[#6D4E1F] text-[18px] text-center"></i>
                      </span>
                      <h4 className="text-[#A3AED0] text-[12px] my-4">
                        قم برفع ملفات PNG , JPG
                      </h4>
                    </label>
                    <input
                      required
                      type="file"
                      id="file"
                      onChange={(e) => {
                        setInfo({ ...info, file: e.target.files[0] });
                      }}
                      style={{ display: "none" }}
                    />
                  </section>
                </div>
              )}
            </div>
            <div className=" my-6 w-full">
              <h4 className=" inputTitle"> جعله الرئيسي</h4>
              <select
                value={main}
                name="main"
                onChange={handleInfoChange}
                className=" input2"
              >
                <option value={"1"}>نعم</option>
                <option value={"0"}>لا</option>
              </select>
            </div>
            <section className=" my-4 w-full text-center">
              <button onClick={addHandeller} className="mainBtn2 w-full">
                {" "}
                اضافة العلان جديد
              </button>
            </section>
          </div>
        </div>
      </Layout>
    </div>
  );
}
