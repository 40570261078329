import React, { useState } from "react";
import SideTab from "../../components/pages/SideTab";
import Tab from "../../components/pages/Tab";
import Layout from "../../components/Layout/Layout";
import Personal from "./Personal";
import Password from "./Password";
import Polices from "./Polices";

export default function Settings() {
  const [tab, setTab] = useState(1);
  const handelChangeTab = (e) => {
    setTab(+e.target.name);
  };
  return (
    <div>
      <Layout title={"الاعدادات"}>
        <div>
          <div className=" flex items-start my-3 lg:hidden justify-start gap-1 flex-wrap">
            <Tab
              text={"بيانات المستخدم"}
              value={1}
              tab={tab}
              handleChange={handelChangeTab}
            />
            <Tab
              text={"كلمة المرور"}
              value={2}
              tab={tab}
              handleChange={handelChangeTab}
            />
            <Tab
              text={"الشروط والاحكام"}
              value={3}
              tab={tab}
              handleChange={handelChangeTab}
            />
          </div>
          <div className=" flex">
            <div className=" w-fit hidden lg:flex">
              <section className="border-l my-[-.8rem] bg-white w-[200px] py-8 px-2 h-screen ">
                <SideTab
                  text={"بيانات المستخدم"}
                  value={1}
                  tab={tab}
                  handleChange={handelChangeTab}
                />
                <SideTab
                  text={"كلمة المرور"}
                  value={2}
                  tab={tab}
                  handleChange={handelChangeTab}
                />
                <SideTab
                  text={"الشروط والاحكام"}
                  value={3}
                  tab={tab}
                  handleChange={handelChangeTab}
                />
              </section>
            </div>
            <div className=" w-full py-12 px-4">
              {tab == 1 && <Personal />}
              {tab == 2 && <Password />}
              {tab == 3 && <Polices />}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
