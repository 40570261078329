import React, { useContext } from "react";
import { isLogged } from "../../utils/inStore";
import Error from "../Error/Error";


export default function LoginLayout({ children }) {
  const isLogIn = useContext(isLogged).logged;

  if (isLogIn) {
    return <Error />;
  } else {
    return (
      <>
        <div className="z-0 w-full overflow-hidden  absolute top-0 left-0">
          <svg
            width="679"
            height="318"
            viewBox="0 0 679 318"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M678 -31.1278C541.404 268.099 188.614 399.733 -110.048 262.878M626.584 -54.6739C505.354 210.938 192.176 327.763 -72.881 206.304C-201.768 147.226 -295.561 42.7066 -344 -79.605M581.654 -75.29C473.823 160.916 195.313 264.849 -40.4999 156.814C-155.083 104.342 -238.509 11.3829 -281.577 -97.451M540.287 -94.2015C444.792 114.943 198.185 206.943 -10.5646 111.32C-112.068 64.7611 -185.923 -17.5436 -224.046 -113.859M499.664 -112.847C416.345 69.7154 201.003 150.049 18.7858 66.5724C-69.7971 25.9794 -134.294 -45.8841 -167.579 -130"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className=" overflow-hidden  bg-[#0D8F75] w-full h-screen flex items-center justify-center">
          <div className="z-30 flex items-center flex-wrap bg-white rounded-lg overflow-hidden w-[85%] md:w-[65%] lg:w-fit">
            <div className="  w-full lg:w-[400px]">
              <div className="h-full w-full rtl px-10 py-8">{children}</div>
            </div>
            <div
              style={{ backgroundImage: `url(images/login.jpg)` }}
              className="bg-center bg-origin-content bg-cover w-full lg:w-[400px]"
            >
              <div className=" flex justify-center items-center bg-black/60 rtl  py-12 md:py-24 lg:py-44 px-8 text-white h1-bold h-full w-full">
                <section>
                  <h1 className="mb-3">مرحبا بك في</h1>
                  <h1>كوبون</h1>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
